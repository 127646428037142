import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
const buttonNameSignup = "signup";
const buttonNameSignin = "signin";
const App = () => {
    const [activeButton, setActiveButton] = useState(buttonNameSignin);
    return (_jsxs("div", Object.assign({ className: "wrapper" }, { children: [_jsxs("header", Object.assign({ className: "customer-dash-nav px-4 my-4" }, { children: [_jsx("div", Object.assign({ className: "" }, { children: _jsx("a", Object.assign({ href: "https://sezzle.com" }, { children: _jsx("img", { src: "https://media.sezzle.com/branding/2.0/Sezzle_Logo_FullColor.svg", alt: "Sezzle logo" }) })) })), _jsxs("div", Object.assign({ className: "" }, { children: [_jsx("button", Object.assign({ type: "button", className: `mr-4${activeButton === "signin" ? " active" : ""}`, onClick: () => setActiveButton(buttonNameSignin) }, { children: "Sign in" })), _jsx("button", Object.assign({ type: "button", className: `${activeButton === "signup" ? "active" : ""}`, onClick: () => setActiveButton(buttonNameSignup) }, { children: "Sign up" }))] }))] })), _jsx("div", Object.assign({ className: "body alignc" }, { children: (() => {
                    if (activeButton === buttonNameSignup) {
                        return (_jsxs("div", Object.assign({ className: "mt-12" }, { children: [_jsx("h1", Object.assign({ className: "h2 mb-8" }, { children: "Sign Up For Sezzle" })), _jsx("p", Object.assign({ className: "mb-8" }, { children: "Please select the account type you would like to create" })), _jsx("a", Object.assign({ className: "button btn-lg mx-2 my-4", href: `${document.location.origin}/customer/signup` }, { children: "Shopper Sign Up" })), _jsx("a", Object.assign({ className: "button btn-lg btn-transparent mx-2 my-4", href: `${document.location.origin}/merchant/signup` }, { children: "Merchant Sign Up" })), _jsxs("p", Object.assign({ className: "mt-12" }, { children: ["Already have an account?", " ", _jsx("button", Object.assign({ className: "link", onClick: () => setActiveButton(buttonNameSignin) }, { children: "Sign In" }))] }))] })));
                    }
                    return (_jsxs("div", { children: [_jsx("h1", Object.assign({ className: "h2 mb-8" }, { children: "Sign In To Your Account" })), _jsx("p", Object.assign({ className: "mb-8" }, { children: "Please select the account you would like to sign into." })), _jsx("a", Object.assign({ className: "button btn-lg mx-2 my-4", href: `${document.location.origin}/customer` }, { children: "Shopper Sign In" })), _jsx("a", Object.assign({ className: "button btn-lg btn-transparent mx-2 my-4", href: `${document.location.origin}/merchant` }, { children: "Merchant Sign In" })), _jsxs("p", Object.assign({ className: "mt-12" }, { children: ["Don't have an account?", " ", _jsx("button", Object.assign({ className: "link", type: "button", onClick: () => setActiveButton(buttonNameSignup) }, { children: "Sign Up" }))] }))] }));
                })() }))] })));
};
export default App;
